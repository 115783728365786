ul.menu {
	position: absolute;
	min-width: 10em;
    right: 0;
}

.menu {
	width: calc(0.5vw + 1.75em);
	height: calc(0.5vw + 1.75em);
	position: relative;
	margin: 0.5em 0em 0 1em;
}
.menu>button {
	background: transparent url('../img/icons/menu.svg') no-repeat center / contain;
	padding: 0;
    height: 100%;
    width: 100%;
    margin: 0;
}
.menu>button:hover {
	box-shadow: none;
}
.menu nav {
	position: absolute;
	background-color: #fff;
	border-radius: 0.25em;
	list-style: none;
	padding: 0;
	margin: 0;
	top: 3em;	
	right: -0.5em;
	filter: 
		drop-shadow(0.15em 0.25em 2em rgba(0,0,0,0.15)) 
		drop-shadow(0.01em 0.1em 0.15em rgba(0,0,0,0.1))	
	;
	animation: showing 0.3s ease-out;
	z-index: 1;
	min-width: 9em;
	border-radius: 0.5em;
}
.menu nav ul {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	padding: 0;
	margin: 0;
}
.menu li {
	padding: 0;
	display: flex;
	align-items: center;
	cursor: pointer;
	z-index: 2;
	position: relative;
}
.menu li a {
	background-color: #fff;
	padding: 0.8em 1.6em;
	flex: 1;
	white-space: nowrap;
	text-decoration: none;
	font-weight: bold;
}
.menu nav ul:first-child li:first-child a::before {
	content: "";
	position: absolute;
	top: -0.25em;
	right: 0.8em;
	z-index: 0;
	width: 1.5em;
	height: 1.5em;
	transform: rotate(45deg);
	background-color: #fff;
	border-radius: 0.25em;
}
.menu nav ul:first-child li:first-child a:hover::before,
.menu li a:hover {
	color: var(--special-color);
	background-color: var(--special-bgcolor)
}
.menu ul:empty + ul li:first-child a,
.menu ul:first-child li:first-child a {
	border-radius: var(--corner-radius) var(--corner-radius) 0 0;
}
.menu ul:last-child li:last-child a {
	border-radius: 0 0 var(--corner-radius) var(--corner-radius);
}
.menu li.selected {
	background-color: #fff5b1;
	color: #79580c;
	cursor: defalt;
}
.menu li img {
	margin: 0.25em 0 0;
	height: 1.75em;
}
.menu li button {
	margin: 0.1em 0em;
    flex: 1;
    padding: 0.6em 1em;
    font-size: 0.9em;
}
.menu ul + ul a {
	font-size: 0.95em;
	font-weight: 400;
	background-color: rgb(248, 248, 248);
}
.menu ul + ul li:first-child a {
	box-shadow: 0 1px 0 0px rgba(0,0,0,0.125) inset;
}
.menu li.logout button {
	color: var(--color-error);
	font-weight: 600;
	background-color: rgb(248, 248, 248);
	text-align: left;
	padding: 0.8em 1.6em;
	border-radius: 0 0 0.5em 0.5em;
	margin: 0;
}
.menu li.logout button:hover {
	background-color: var(--color-error);
	color:rgb(248, 248, 248);
	box-shadow: unset;
	transform: unset;
	transition: none; 
}