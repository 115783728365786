ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


.adcover {
    background-color: var(--color-background);
}
.adcover::before,
.adcover::after {
    content: unset;
}
.ads {
    /* column-count: 3; */
    column-gap: 2.5em;
    padding: 2.5em 1em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: stretch;
}
.ads {
    margin-bottom: -2.5em;
}
.ads li {
    display: inline-block;
    width: 100%;
    margin-bottom: 2.5em;
}
.special.ads {
    column-count: 1;
    grid-column: -2 / -1;
}



.ads .card {
    display: block;
    border-radius: 0.35rem;
    background-color: var(--color-surface);
    filter: drop-shadow(1px 1px 2px var(--color-shadow));
    padding: 0.75em 0.75em 3em 0.75em;
    position: relative; 
    -webkit-hyphens: auto;
    -webkit-hyphenate-limit-before: 3;
    -webkit-hyphenate-limit-after: 3;
    -webkit-hyphenate-limit-chars: 6 3 3;
    -webkit-hyphenate-limit-lines: 2;
    -webkit-hyphenate-limit-last: always;   
    -webkit-hyphenate-limit-zone: 8%;

    -moz-hyphens: auto;
    -moz-hyphenate-limit-chars: 6 3 3;
    -moz-hyphenate-limit-lines: 2;  
    -moz-hyphenate-limit-last: always;
    -moz-hyphenate-limit-zone: 8%;

    -ms-hyphens: auto;
    -ms-hyphenate-limit-chars: 6 3 3;
    -ms-hyphenate-limit-lines: 2;
    -ms-hyphenate-limit-last: always;   
    -ms-hyphenate-limit-zone: 8%;

    hyphens: auto;
    hyphenate-limit-chars: 6 3 3;
    hyphenate-limit-lines: 2;
    hyphenate-limit-last: always;   
    hyphenate-limit-zone: 8%;
}
.ads .card figure {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: -0.75em 0.75em 0.125em -0.75em;
    border-radius: 0.35rem 0 0 0;
    width: 47%;
    float: left;
}
.ads .card img {
    width: 100%;
}
.ads .card h3 {
    margin: 0 0 0.25em 0;
    color: var(--special-color);
    font-size: 1em;
    text-transform: uppercase;
    text-align: left;
}
.ads .card .accept {
    line-height: 1.1;
    grid-column: span 2;
    margin: 0.75em 0 0.75em 0;
    color: var(--color-text-default);
}
.ads .card .accept_others {
    display: table;
    margin: 1em 0 0 -0.1em;
    font-weight: 700;
    padding: 0.65em 0.6em 0.45em 0.7em;
    border-radius: 0.25em;
    box-shadow: 0px 0px 0px 1px #c8c0da;
    background-color: #f4f2f7;
    font-size: 0.7em;
    color: #70678f;
    text-transform: uppercase;
}
.ads .card .description {
    grid-area: 2 / 2 / 2 / 2;
    margin: 0.75em 0 0 0;
    line-height: 1.3;
    color: var(--color-text-default);
}
.ads b {
    margin-right: 0.5em;
    font-weight: 800;
    display: inline-block;
}


/* super */
.ads .card.super .actions {
    margin-top: 0em;
}
.ads .card.super {
    /* display: grid;
    grid-template-columns: 4fr 5fr;
    gap: 0.75em; */
    grid-row: span 2;
}
.ads .card.super figure {
    grid-column: 1 / 3;
    margin: -0.75em -0.75em 1.5em -0.75em;
    border-radius: 0.35rem 0.35rem 0 0;
    filter: drop-shadow(0 0.36em 0 var(--special-bgcolor));
    float: none;
    width: calc(100% + 1.5em);
}
.ads .card.super header {
    grid-column: 1 / 3;
}
.ads .card.super span {
    font-size: 1.1em;
}
