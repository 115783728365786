.start {
	display: flex;
	flex-flow: wrap row;
}
.start section {
	overflow: unset;
}
.start .first-message {
	display: flex;
	justify-content: center;
	margin: 1em 0;
}
.start .first-message h1 {
	background-color: transparent;
	color: var(--color-text-default);
	display: flex;
	flex-direction: column;
	box-shadow: none;
	width: 50%;
	padding: 1em 2em;
	font-size: 1em;
	align-items: flex-start;
    justify-content: center;
}
.start .first-message h1>span {
	font-size: calc(1.4em + 0.8vw);
	text-transform: none;
}
.start .first-message h1>button {
	font-size: calc(1em + 0.8vw);
	margin: 1em -0.05em;
}
.start .first-message figure {
	width: calc(56% + 8em);
	margin: 0;
	padding: 2em 1em 2em 0;
}
.start .first-message img {
	width: 100%;
}
.destaque {
	padding-bottom: 54%;
	position: relative;
	max-width: unset;
	background: transparent url(/img/destaque-bg.png) center repeat-x;
	background-size: cover;
	flex: 0 0 100%;
}
.destaque div {
	background: transparent url(/img/destaque.jpg) center no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.video {
	flex: 0 0 73.333%;
	margin: 0 0 0 auto;
	max-width: 55em;
}
/*
section.impacto::before,
section.video::after {
	width: 0;
}
*/
.video-apresentacao {
	padding-bottom: 56%;
	position: relative;
}
.video-apresentacao iframe {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}
.impacto {
	flex: 0 0 26.666%;
	max-width: 20em;
	margin: 0 auto 0 0;
	padding: 0 0 0 2.65em;
}
.impacto img {
	width: 100%;
	margin: 0 0 -0.35em 0;
}


.instrucoes {
	text-align: center;
	background-color: #faf1d4;
}
.instrucoes ul {
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 3em;
}
.instrucoes h2 {
	margin: 2em 0 1.25em 0;
	text-transform: uppercase;
	color: var(--color-text-default);
	font-size: 1.5em;
}
.instrucoes li {
	padding: 0 2.5em;
	width: 25%;
}
.instrucoes img {
	width: 8em;
	margin: -0.5em;
}
.instrucoes h4 {
	margin: 0;
}
.instrucoes li p {
	line-height: 1.35;
	font-size: 1.1em;
}
.instrucoes button {
	font-size: 1.2em;
}



.categories {
	text-align: center;
	background-color: var(--special-color);
	padding: 1em 0 1.5em 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.categories h3 {
	padding: 0.5em 1em;
}
.categories ul {
	list-style: none;
	padding: 1em 0 0.5em 0;
	width: 100%;
	white-space: nowrap;
	overflow-x: auto;
}
.categories li {
	margin: 0 0.25em;
	width: 8em;
	min-width: 7em;
	white-space: normal;
	display: inline-block;
}
.categories a {
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-items: center;
}
.categories li figure {
	border-radius: 50%;
	width: 4.75em;
	height: 4.75em;
	margin: 0;
}
.categories li img {
	width: 100%;
	height: 100%;
    filter: invert(1);
}
.categories li h4 {
	font-size: 1em;
	color: var(--color-background);
	line-height: 1.3;
	margin-top: 1em;
}
.categories form {
	width: 80%;
	max-width: 50em;
	margin: 2em;
}

.categories ul::-webkit-scrollbar {
	width: 0.75em;
}
.categories ul {
	scrollbar-width: thin;
	scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.categories ul::-webkit-scrollbar-track {
	background: var(--scrollbarBG);
	cursor: pointer;
}
.categories ul::-webkit-scrollbar-thumb {
	background-color: var(--thumbBG) ;
	border-radius: 0.5em;
	border: 0.125em solid var(--scrollbarBorder);
}




.adcover {
	max-width: 100%;
	padding-left: calc(50% - 600px);
	padding-right: calc(50% - 600px);
	background-color: var(--color-background);
}
.adcover button.more {
	width: 80%;
    margin: 1em auto;
    display: block;
    font-size: 1.2em;
}

