@keyframes openning-msg {
    0% {
        opacity: 0;
        top: -4em;
        transform: translateX(-50%) scale(0.5);
    }
    50% {
        opacity: 0;
        top: -4em;
        transform: translateX(-50%) scale(0.5);
    }
    100% {
        opacity: 1;
        top: 2.5em;
        transform: translateX(-50%) scale(1);
    }
}
@keyframes processing {
    0% {
        width: 0;
        opacity: 0.1;
    }
    100% {
        width: 100%;
        opacity: 0;
    }
}
        
.msg {
	animation: openning-msg 0.5s cubic-bezier(.5,1.75,.8,1), openning-msg 0.75s 9s forwards cubic-bezier(.5,1.75,.8,1) reverse;
    position: fixed;
    z-index: 2;
    border-radius: 0.3em;
    top: 2.5em;
    left: 50%;
    -ms-transform: translateY(-50%);
    transform: translateX(-50%);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}
.msg::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0%;
    height: 100%;
    background-color: inherit;
    animation: processing 10s 0.5s;
}
.msg span {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    background-color: rgba(255,255,255,0.8);
}
div.msg button.close[type="button"] {
    position: static;
    top: -0.5em;
    right: -0.5em;
    background-color: rgba(255,255,255,0.1);
    width: 3em;
    min-width: 2em;
    min-height: 3em;
    padding: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0.3em 0.3em 0;
    font-size: 1em;
}
.msg .close:hover {
    color: #fff;
    background-color: rgba(255,255,255,0.3);
}
.error {
    color: var(--color-error);
    box-shadow: inset 0 0 0 0.1em var(--color-error);
    background-color: var(--color-error);
}
.success {
    color: var(--color-success);
    box-shadow: inset 0 0 0 0.1em var(--color-success);
    background-color: var(--color-success);
}
.info {
    color: var(--color-info);
    box-shadow: inset 0 0 0 0.1em var(--color-info);
    background-color: var(--color-info);
}
p.info {
    background-color: var(--color-info-background);
    box-shadow: inset 0 0 0 0.1em var(--color-info);
    color: var(--color-info);
    padding: 0.5em 1em;
    border-radius: 0.25em;
    margin: 0 0 1.5em 0;
}

