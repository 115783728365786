.chat {
    background-color: var(--chat-color-bg);
    border-radius: var(--corner-radius);
    --form-chat-height: 6em;
}

.chatDialog .window {
    height: 99%;
}
.chatDialog .content {
    overflow: unset;
    height: 100%;
}
.dialog .chat {
    margin: -1.4em -1.2em 0em -1.2em;
    box-shadow: 0 0 0 1px var(--chat-color-bg);
    height: calc(100% - 2.2em);
}
.chat .messages {
    padding: 1em;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    overflow: auto;
    height: calc(100% - var(--form-chat-height));
}
.chat .first-message {
    background-color: #fff;
    border-radius: var(--corner-radius);
    filter: var(--elevation-shadow1);
    padding: 1em;
}
.chat .message {
    line-height: 1.3;
    margin: 0 0 1em 0;
    display: flex;
    gap: 0.5em;
    align-self: start;
}
.chat .message.right {
    flex-direction: row-reverse;
    align-self: end;
}
.chat .message p {
    margin: 0;
}
.chat .message br {
    display: block;
    margin: 0.5em 0 0 0;
    content: "";
}
.chat .first-message {
}
.chat .balloon {
    background-color: var(--color-background);
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.8));
    filter: var(--elevation-shadow1);
    border-radius: var(--corner-radius);
    padding: 0.9em 1em;
    margin: 0;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
}
.chat .message.right .balloon {
    background-color: #d9fdd3;
}
.chat .balloon span {
    font-weight: 600;
    font-size: 1.1em;
    position: relative;
    z-index: 2;
    line-height: 1.2;
    white-space: pre-wrap;
}
.chat .balloon::before {
    content: "";
    background-color: inherit;
    position: absolute;
    top: 1.5em;
    left: -0.3em;
    width: 1em;
    height: 1em;
    grid-area: text;
    transform: rotate(45deg);
}
.chat .message.right .balloon::before {
    left: auto;
    right: -0.3em;
}
.avatar {
    width: 3.75em;
    height: 3.75em;
    background: rgba(var(--special-color-values), 0.2) url('https://auati.com.br/images/person/placeholder-person.svg') center / contain;
    border-radius: 500px;
    margin: 0 0.5em 0 -0.25em;
    flex: 0 0 auto;
}
.chat .message.right .avatar {
    margin: 0 -0.25em 0 0.5em;
}
.avatar img {
    width: 100%;
}
.chat p time {
    font-size: 0.8em;
    margin: 0.25em -0.25em -0.75em auto;
    color: var(--text-gray);
    font-weight: normal;
}
.chat samp {
    background-color: rgba(var(--neutral-values), 0.2);
    border: 1px rgba(var(--neutral-values), 0.1) solid;
    font: inherit;
    font-size: 0.9em;
    padding: 0.4em 1em;
    margin: 1em 0 3em 0;
    border-radius: var(--corner-radius);
}
.chat form {
    height: var(--form-chat-height);
    padding: 0.5em;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5em;
}
.chat form .field {
    height: 100%;
    margin: 0;
}
.chat form textarea {
    height: 100%;
    min-height: auto;
    resize: none;
}
.chat form footer {
    display: flex;
    flex-flow: column;
    flex-direction: row;
}
.chat form button[type="submit"] {
    margin: 0;
    flex: 1;
    height: 100%;
}
.chat textarea:invalid {
    box-shadow: inset 0 0 0 0.075em rgba(var(--neutral-values), 0.5);
    background-color: var(--color-background);
    color: rgba(var(--neutral-values), 0.9);
}